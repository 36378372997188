/* src/app/form/preview-form-questions-dialog/preview-form-questions-dialog.component.scss */
.preview-form {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
.preview-form ::ng-deep .mat-mdc-paginator-range-label {
  display: none;
}
.preview-form_form-page {
  flex: 1;
  overflow-y: auto;
}
.preview-form_close-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.preview-form_form-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0 0.5rem;
  text-align: center;
}
/*# sourceMappingURL=preview-form-questions-dialog.component.css.map */
