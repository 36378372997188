import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../store/models/app.state';
import { resetCurrentForm, searchFormById } from '../store/form.actions';
import { selectCurrentFormStateForm } from '../store/form.state';
import { FormModel } from '../form.model';

@Component({
  selector: 'phar-preview-form-questions-dialog',
  templateUrl: 'preview-form-questions-dialog.component.html',
  styleUrls: ['preview-form-questions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewFormQuestionsDialogComponent implements OnInit, OnDestroy {
  form$: Observable<FormModel>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<PreviewFormQuestionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { form: FormModel },
  ) {
  }

  ngOnInit(): void {
    this.form$ = this.store.select(selectCurrentFormStateForm);
    this.store.dispatch(searchFormById({ id: this.data.form.id }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetCurrentForm({ formType: null }));
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }
}
