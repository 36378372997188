import { appStateUser, UserState } from './user.state';
import * as userActions from './user.actions';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { AppState } from '../../store/models/app.state';

const reducer = createReducer(appStateUser.user,
  on(userActions.pending, (state, { pending }) => ({ ...state })),
  on(userActions.loadUsersCountGroupByStatus, (state, { usersCount }) => ({
    ...state,
    usersCountByStatus: { ...usersCount }
  })),
  on(userActions.resetUserProfile, (state) => ({
    ...state, profile: {
      userId: null,
      organizationId: null,
      sub: '',
      email: '',
      family_name: '',
      given_name: '',
      userName: '',
      subscriptionlevel: '',
      statusId: null,
      country: '',
      address: '',
      amr: [],
      userIdUpdated: null,
      userIdCreated: null,
      updatedAt: '',
      createdAt: '',
      isPending: false,
      pinCode: null,
      phoneNumber: null,
      customerIdentifier: null,
      dateOfBirthDay: null,
      dateOfBirthMonth: null,
      dateOfBirthYear: null,
      customFields: []
    }
  })),
  on(userActions.updateUserProfile, (state, { user }) => ({ ...state, profile: user })),
  on(userActions.updateCurrentUser, (state, { user }) => ({ ...state, current: user })),
  on(userActions.resetCurrentUser, (state) => ({
    ...state,
    current: {
      userId: null,
      organizationId: null,
      sub: '',
      email: '',
      family_name: '',
      given_name: '',
      userName: '',
      subscriptionlevel: '',
      statusId: null,
      country: '',
      address: '',
      amr: [],
      userIdUpdated: null,
      userIdCreated: null,
      updatedAt: '',
      createdAt: '',
      isPending: false,
      pinCode: null,
      phoneNumber: null,
      customerIdentifier: null,
      dateOfBirthDay: null,
      dateOfBirthMonth: null,
      dateOfBirthYear: null,
      customFields: []
    }
  })),
  on(userActions.setError, (state, { errors }) => ({ ...state, errors })),
  on(userActions.resetField, (state, { field, value }) => ({ ...state, [field]: value })),
  on(userActions.resetError, (state) => ({ ...state, errors: [] })),
  on(userActions.updateUserList, (state, { users }) => ({ ...state, list: users })),
  on(userActions.resetUserList, (state) => ({ ...state, list: [] })),
  on(userActions.updateUserProfileField, (state, { value, field }) => ({
    ...state,
    profile: {
      ...state.profile,
      [field]: value
    }
  })),
  on(userActions.updateCurrentUserCustomFields, (state, { value, field }) => {
    const currentCustomFields = [...state.current.customFields];
    const customField = currentCustomFields.find(el => el.keyId === field);
    if (customField) {
      customField.value = value;
    }
    return {
      ...state, current: { ...state.current, customFields: [...currentCustomFields] }
    }
  }),
  on(userActions.updateCurrentUserField, (state, { value, field }) => ({
    ...state,
    current: {
      ...state.current,
      [field]: value
    }
  })),
);

export function userReducer(state: UserState, action: Action): UserState {
  return reducer(state, action);
}

export const selectUser = (state: AppState) => state.user;
export const selectUserList = createSelector(
  selectUser,
  (user: UserState) => user.list
);
