import { UserModel, UsersCountGroupByStatus } from '../user.model';
import { Profile } from 'oidc-client';
import { createAction, props } from '@ngrx/store';
import { User } from '@auth0/auth0-angular';

export enum ActionTypes {
  UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER',
  RESET_CURRENT_USER = 'RESET_CURRENT_USER',
  UPDATE_CURRENT_USER_FIELD = 'UPDATE_CURRENT_USER_FIELD',
  UPDATE_CURRENT_USER_CUSTOM_FIELD = 'UPDATE_CURRENT_USER_CUSTOM_FIELD',
  ASSIGN_USER_TO_ORGANIZATION = 'ASSIGN_USER_TO_ORGANIZATION',
  PENDING = 'PENDING',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  RESET_ERROR = 'RESET_ERROR',
  SET_ERRORS = 'SET_ERRORS',
  RESET_FIELD = 'RESET_FIELD',
  LOGGED = 'LOGGED',
  RESET_USER_PROFILE = 'RESET_USER_PROFILE',
  GET_USER_BY_SUB = 'GET_USER_BY_SUB',
  GET_USER_BY_ID = 'GET_USER_BY_ID',
  GET_NAVIGATOR_USERS = 'GET_NAVIGATOR_USERS',
  UPDATE_USER_LIST = 'UPDATE_USER_LIST',
  RESET_USER_LIST = 'RESET_USER_LIST',
  GET_USERS_BY_ORGANIZATION = 'GET_USERS_BY_ORGANIZATION',
  UPDATE_USER_PROFILE_FIELD = 'UPDATE_USER_PROFILE_FIELD',
  GET_PENDING_USERS_BY_ORGANIZATION = 'GET_PENDING_USERS_BY_ORGANIZATION',
  INVITE_USER_REQUEST = 'INVITE_USER_REQUEST',
  GET_INVITED_USERS_BY_ORGANIZATION = 'GET_INVITED_USERS_BY_ORGANIZATION',
  HANDLE_PENDING_USERS = 'HANDLE_PENDING_USERS',
  RESEND_USERS_INVITATIONS = 'RESEND_USERS_INVITATIONS',
  DELETE_USER_INVITATION = 'DELETE_USER_INVITATION',
  LOAD_USERS_COUNT_GROUP_BY_STATUS = '[User] Load Users count group by status',
  FETCH_USERS_COUNT_GROUP_BY_STATUS = '[User] Fetch Users count group by status'
}

export const loadUsersCountGroupByStatus = createAction(ActionTypes.LOAD_USERS_COUNT_GROUP_BY_STATUS, props<{
  usersCount: UsersCountGroupByStatus
}>());


export const fetchUsersCountGroupByStatus = createAction(ActionTypes.FETCH_USERS_COUNT_GROUP_BY_STATUS);

export const getNavigatorUsers = createAction(ActionTypes.GET_NAVIGATOR_USERS);
export const handlePendingUsers = createAction(ActionTypes.HANDLE_PENDING_USERS, props<{
  usersIds: number[],
  approve: boolean
}>());
export const resendUsersInvitations = createAction(ActionTypes.RESEND_USERS_INVITATIONS, props<{
  usersIds: number[]
}>());

export const deleteUserInvitation = createAction(ActionTypes.DELETE_USER_INVITATION, props<{ userId: number }>());

export const updateUserProfileField = createAction(ActionTypes.UPDATE_USER_PROFILE_FIELD, props<{
  field: string,
  value: string | number | any
}>());


export const updateUserList = createAction(ActionTypes.UPDATE_USER_LIST, props<{ users: UserModel[] }>());


export const resetUserList = createAction(ActionTypes.RESET_USER_LIST);

export const getUserById = createAction(ActionTypes.GET_USER_BY_ID, props<{ id: number }>());

export const resetCurrentUser = createAction(ActionTypes.RESET_CURRENT_USER);

export const updateCurrentUserField = createAction(ActionTypes.UPDATE_CURRENT_USER_FIELD, props<{
  field: string,
  value: string | number | any
}>());

export const updateCurrentUserCustomFields = createAction(ActionTypes.UPDATE_CURRENT_USER_CUSTOM_FIELD, props<{
  field: string,
  value: string | number | any
}>());


export const getUserBySub = createAction(ActionTypes.GET_USER_BY_SUB, props<{ user: User }>());


export const resetUserProfile = createAction(ActionTypes.RESET_USER_PROFILE);


export const updateUserProfile = createAction(ActionTypes.UPDATE_USER_PROFILE, props<{ user: UserModel }>());


export const uploadUserAvatar = createAction(ActionTypes.UPLOAD_USER_AVATAR, props<{ formData: FormData }>());


export const updateCurrentUser = createAction(ActionTypes.UPDATE_CURRENT_USER, props<{ user: UserModel }>());


export const updateUser = createAction(ActionTypes.UPDATE_USER, props<{
  user: UserModel,
  isCurrentProfile: boolean
}>());


export const assignUserToOrganization = createAction(ActionTypes.ASSIGN_USER_TO_ORGANIZATION, props<{
  userId: number,
  organizationId: number
}>());

export const pending = createAction(ActionTypes.PENDING, props<{ pending: boolean }>());


export const loginSuccess = createAction(ActionTypes.LOGIN_SUCCESS, props<{ success: boolean }>());


export const loginFailed = createAction(ActionTypes.LOGIN_FAILED, props<{ failed: boolean }>());


export const resetError = createAction(ActionTypes.RESET_ERROR);

export const setError = createAction(ActionTypes.SET_ERRORS, props<{ errors: string[] }>());

export const resetField = createAction(ActionTypes.RESET_FIELD, props<{ field: string, value: any }>());


export const logged = createAction(ActionTypes.LOGGED, props<{ isLogged: boolean }>());


export const getUsersByOrganization = createAction(ActionTypes.GET_USERS_BY_ORGANIZATION);

export const getPendingUsersByOrganization = createAction(ActionTypes.GET_PENDING_USERS_BY_ORGANIZATION);

export const inviteUserRequest = createAction(ActionTypes.INVITE_USER_REQUEST, props<{ invitationData: any }>());

export const getInvitedUsersByOrganization = createAction(ActionTypes.GET_INVITED_USERS_BY_ORGANIZATION);
